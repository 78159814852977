var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "mew-component--module-side-apr pa-5 border-radius--10px box-border",
    },
    [
      _c(
        "div",
        { staticClass: "d-flex align-top justify-space-between mb-1" },
        [
          _vm._m(0),
          _c("div", { staticClass: "text-right" }, [
            _c("div", { staticClass: "font-weight-bold mew-heading-3 mb-1" }, [
              _vm._v(" " + _vm._s(_vm.formattedBalance) + " "),
            ]),
            _vm.ethvmSupport
              ? _c("div", { staticClass: "textLight--text" }, [
                  _vm._v(" " + _vm._s(_vm.rethBalanceFiat) + " "),
                ])
              : _vm._e(),
          ]),
        ]
      ),
      _vm.hasStakedNoRewards
        ? _c("div", { staticClass: "mt-4" }, [
            _vm._v(
              " You have not earned any rewards yet. Please wait 24 hours after staking to start earning rewards. "
            ),
          ])
        : _vm._e(),
      _vm.hasBalance
        ? _c(
            "div",
            {
              staticClass:
                "d-flex align-center justify-space-between flex-wrap-reverse mt-4",
            },
            [
              _vm.isEthNetwork
                ? _c("mew-button", {
                    staticClass: "mew-body",
                    attrs: {
                      title: "Redeem to ETH",
                      "btn-style": "transparent",
                      "btn-size": "small",
                      disabled: !_vm.hasBalance || _vm.enoughToCoverRedeem,
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.executeSwap.apply(null, arguments)
                      },
                    },
                  })
                : _vm._e(),
              _c("mew-button", {
                staticClass: "mew-body",
                attrs: {
                  title: "Compound",
                  "btn-style": _vm.compoundRewards
                    ? "transparent"
                    : "background",
                  "btn-size": "small",
                  disabled: !_vm.hasBalance || _vm.enoughToCoverRedeem,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.scrollToInput.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.enoughToCoverRedeem
        ? _c("div", { staticClass: "mt-4 greyPrimary--text" }, [
            _vm._v(" You do not have enough ETH to cover transaction fee. "),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex align-center mt-n4" }, [
      _c("div", { staticClass: "stake-icon mr-2" }, [
        _c("img", {
          attrs: {
            src: require("@/dapps/stakewise/assets/icon-stakewise-red.svg"),
            alt: "Stakewise",
          },
        }),
      ]),
      _c("div", { staticClass: "textLight--text" }, [
        _c("span", { staticClass: "textMedium--text" }, [_vm._v("Rewards")]),
        _vm._v(" - rETH2 "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }